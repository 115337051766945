<template>
  <div class="elv-ledger-process-table">
    <ag-grid-vue
      v-if="!!tableConfigStore.processTableConfig.length || !!processTableConfig.length"
      :style="{
        height: '100%',
        minWidth: '406px',
        maxWidth: '100%'
      }"
      class="elv-ag-theme-alpine"
      :rowHeight="42"
      :grid-options="gridOptions"
      :column-defs="processTableConfig"
      :loading-cell-renderer="TableLoadingCell"
      :loading-cell-renderer-params="loadingCellRendererParams"
      :is-server-side-group-open-by-default="isServerSideGroupOpenByDefault"
      :get-row-id="getRowId"
      :get-row-height="getRowHeight"
      @cellClicked="onCellClicked"
      @grid-ready="onGridReady"
    />
  </div>
</template>
<script setup lang="ts">
import { AgGridVue } from 'ag-grid-vue3'
import { LicenseManager } from 'ag-grid-enterprise'
import { themeAlpine } from '@ag-grid-community/theming'
import { type GridApi, DetailGridInfo, IServerSideGetRowsParams } from 'ag-grid-enterprise'

import { isEmpty, uniqueId } from 'lodash-es'
import noDataImg from '@/assets/img/noData.png'
import { useEntityStore } from '@/stores/modules/entity'
import { useLedgerStore } from '@/stores/modules/ledger/index'
import CustomTooltip from '@/components/Reports/Cell/Tooltips.vue'
import TableLoadingCell from '@/components/Reports/Cell/TableLoadingCell.vue'
import { useLedgerTableConfigStore } from '@/stores/modules/ledger/tableConfig'

LicenseManager.setLicenseKey(import.meta.env.VITE_TABLE_KEY)

const props = defineProps({
  type: {
    type: String,
    required: true
  },
  height: {
    type: String,
    default: `calc(100vh - 219px)`
  },
  dateTimeValue: {
    type: Array,
    required: true
  },
  summaryParams: {
    type: Object,
    required: true
  }
})

const route = useRoute()

const entityStore = useEntityStore()
const ledgerStore = useLedgerStore()
const tableConfigStore = useLedgerTableConfigStore()
const { processTableConfig } = storeToRefs(tableConfigStore)
const columnData = ref()
const tableDataList: any = ref([])
const gridApi = ref<GridApi | null>(null)

const overlayDrawerData: any = reactive({
  currentData: {}
})

const loadingCellRendererParams = reactive({
  loadingMessage: 'Loading'
})
const groupLitsData: any = reactive({
  group: {},
  listData: {}, // 分组后list的数据
  total: {}, // 总计数据
  totalCount: 0
})

const cacheBlockSize = computed(() => {
  return props.type === 'general-ledger' ? 10 : 50
})

// ag-grid基础配置
const gridOptions: any = reactive({
  sidebar: false,
  tooltipShowDelay: 500,
  headerHeight: 35,
  // tooltipHideDelay: 5000,
  // columnHoverHighlight: true,
  theme: themeAlpine,
  rowModelType: 'serverSide',
  groupDisplayType: 'custom', // custom || groupRows
  suppressRowTransform: true,
  suppressContextMenu: true,
  animateRows: true,
  debounceVerticalScrollbar: true,
  suppressPropertyNamesCheck: true,
  suppressServerSideFullWidthLoadingRow: true,
  suppressColumnVirtualisation: false, // 开启列虚拟化
  rowGroupPanelShow: 'never',
  // serverSideInfiniteScroll: true, // 设置服务器端行模型是否使用无限滚动
  maxConcurrentDatasourceRequests: 1, // 有多少请求同时命中服务器。如果达到最大值，请求将排队
  cacheBlockSize: cacheBlockSize.value, // 缓存中每个块有多少行，即一次从服务器返回多少行
  blockLoadDebounceMillis: 100, // 加载块之前等待多少毫秒。在无限滚动和滚动许多无限块时很有用，因为它可以防止块加载直到滚动结束。
  serverSideInitialRowCount: 0, // 初始化从第几行开始显示
  defaultColDef: {
    resizable: true,
    suppressMovable: true,
    cellStyle: {
      height: '42px',
      'line-height': '42px' // 行高设置同步：跟rowHeight属性设置值一致
    },
    tooltipComponent: CustomTooltip,
    tooltipValueGetter: (params: any) => {
      return params?.value
    },
    debug: import.meta?.env.MODE === 'development'
  },
  overlayLoadingTemplate: '<span class="ag-overlay-loading-center">data is loading...</span>',
  overlayNoRowsTemplate: `<div class="elv-result">
    <img src="${noDataImg}" style="width: 40px; height: 40px;margin-bottom:16px" />
    <div class="elv-result-description">
      No data matches this filter
    </div>
  </div>`
})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const groupBackgroundColor = computed(() => {
  return props.type === 'general-ledger' ? '#fff' : '#f9fafb'
})

const subGroupBackgroundColor = computed(() => {
  return props.type === 'general-ledger' ? '#fafafa' : '#fff'
})

// 单元格点击事件
const onCellClicked = (cell: any) => {
  console.log(cell)
  columnData.value = cell
  overlayDrawerData.currentData = {}
  overlayDrawerData.currentData.rowId = cell.node.id
}

// 分组数据处理
const groupRowDataFormatter = async (params: any) => {
  const data: any = {
    list: [],
    total: {}
  }
  const groupTwoData: any = []
  if (!groupLitsData.group?.list?.length) {
    const response = await ledgerStore.processTableDataInit(entityId.value, props.type, props.summaryParams)
    data.list = response.data
    groupLitsData.group = data
    groupLitsData.total = response.data.length
    if (data?.totalCount) groupLitsData.totalCount = response.data.length
  } else if (params.request?.endRow && params.request.startRow !== 0 && params.request.endRow !== 100) {
    if (params.request.endRow / 30) {
      data.list = groupLitsData.group?.list?.slice(params.request.startRow, params.request.endRow)
    } else {
      data.list = groupLitsData.group?.list?.slice(0, 30)
    }
  } else {
    data.list = groupLitsData.group?.list.slice(0, 30)
  }
  let rowData: any = []
  // 一级分组
  if (data?.list?.length > 0) {
    let groupIndex = 0
    data?.list.forEach((firstLevelGroupData: any, groupOneIndex: number) => {
      const tableFirstGroupData: any = []
      tableFirstGroupData.push({
        ...firstLevelGroupData,
        index: groupOneIndex,
        groupLevel: 1,
        label:
          props.type === 'general-ledger'
            ? firstLevelGroupData?.name
            : firstLevelGroupData?.chartOfAccount?.name ||
              firstLevelGroupData?.entityAccount?.entityAccountId ||
              firstLevelGroupData?.counterparty?.name ||
              firstLevelGroupData?.auxiliaryItem?.value ||
              `${groupOneIndex}Uncategorized`,
        firstLevelGroup: `${groupOneIndex}${
          props.type === 'general-ledger'
            ? firstLevelGroupData?.name
            : firstLevelGroupData?.chartOfAccount?.name ||
              firstLevelGroupData?.entityAccount?.name ||
              firstLevelGroupData?.counterparty?.name ||
              firstLevelGroupData?.auxiliaryItem?.value ||
              `${groupOneIndex}Uncategorized`
        }`
      })
      rowData = [...rowData, ...tableFirstGroupData]
      const tableSecondGroupData: any = []
      // 二级分组
      if (
        firstLevelGroupData.list?.length > 0 &&
        props.summaryParams?.subGroup !== 'NONE' &&
        props.type === 'account-balance'
      ) {
        firstLevelGroupData.list.forEach((secondLevelGroupData: any, groupTwoIndex: number) => {
          groupIndex += 1
          tableSecondGroupData.push({
            ...secondLevelGroupData,
            index: groupTwoIndex,
            groupThreeIndex: groupIndex - 1,
            groupLevel: 2,
            label:
              secondLevelGroupData?.entityAccount?.name ||
              secondLevelGroupData?.auxiliaryItem?.auxiliaryItemId ||
              secondLevelGroupData?.counterparty?.name ||
              secondLevelGroupData?.chartOfAccount?.name ||
              `${groupIndex}Uncategorized${groupTwoIndex}`,
            [`secondLevelGroup`]:
              secondLevelGroupData?.entityAccount?.name ||
              secondLevelGroupData?.auxiliaryItem?.auxiliaryItemId ||
              secondLevelGroupData?.counterparty?.name ||
              secondLevelGroupData?.chartOfAccount?.name ||
              `${groupIndex}Uncategorized${groupTwoIndex}`
          })
        })
      }
      groupTwoData.push(tableSecondGroupData)
    })
  }
  entityStore.loading = false
  return {
    firstLevelGroupData: rowData,
    secondLevelGroupData: groupTwoData
  }
}

// 获取列表数据
const getTableGroupListData = async (params: any) => {
  let groupListParams: any = {
    page: 1,
    limit: 100
  }
  // eslint-disable-next-line prefer-destructuring
  groupListParams.periodBegin = props.dateTimeValue[0]
  // eslint-disable-next-line prefer-destructuring
  groupListParams.periodEnd = props.dateTimeValue[1]
  let tableGroupListData: any = []
  if (props.type === 'general-ledger') {
    groupListParams.chartOfAccountId = params.parentNode?.data?.chartOfAccountId
    groupListParams.limit = 10
    groupListParams.page = params.request.endRow / 10 ? Number((params.request.endRow / 10).toFixed(0)) : 1
  } else {
    groupListParams = {
      ...props.summaryParams
    }
    delete groupListParams.page
    delete groupListParams.limit
    if (
      params.parentNode?.data?.auxiliaryItem?.auxiliaryItemId ||
      params.parentNode?.parent?.data?.auxiliaryItem?.auxiliaryItemId
    ) {
      groupListParams.auxiliaryItemId =
        params.parentNode?.data?.auxiliaryItem?.auxiliaryItemId ||
        params.parentNode?.parent?.data?.auxiliaryItem?.auxiliaryItemId
    }
    if (
      params.parentNode?.data?.entityAccount?.entityAccountId ||
      params.parentNode?.parent?.data?.entityAccount?.entityAccountId
    ) {
      groupListParams.entityAccountId =
        params.parentNode?.data?.entityAccount?.entityAccountId ||
        params.parentNode?.parent?.data?.entityAccount?.entityAccountId
    }
    if (
      params.parentNode?.data?.counterparty?.counterpartyId ||
      params.parentNode?.parent?.data?.counterparty?.counterpartyId
    ) {
      groupListParams.counterpartyId =
        params.parentNode?.data?.counterparty?.counterpartyId ||
        params.parentNode?.parent?.data?.counterparty?.counterpartyId
    }
    if (
      params.parentNode?.data?.chartOfAccount?.chartOfAccountId ||
      params.parentNode?.parent?.data?.chartOfAccount?.chartOfAccountId
    ) {
      groupListParams.chartOfAccountId =
        params.parentNode?.data?.chartOfAccount?.chartOfAccountId ||
        params.parentNode?.parent?.data?.chartOfAccount?.chartOfAccountId
    }
  }
  groupLitsData.listData = await ledgerStore.processTableListDataInit(entityId.value, props.type, groupListParams)
  groupLitsData.totalCount = groupLitsData.listData?.totalCount ?? groupLitsData.listData.list?.length

  const { list } = groupLitsData.listData
  list?.forEach((item: any, index: number) => {
    item.rowId = `${index}${
      // eslint-disable-next-line no-nested-ternary
      item?.chartOfAccount?.chartOfAccountId
        ? item?.chartOfAccount?.chartOfAccountId
        : item?.chartOfAccountId
          ? item?.chartOfAccountId
          : `${index}${Math.floor(Math.random() * 900) + 100}`
    }`
  })
  tableGroupListData = list
  return tableGroupListData
}

// 获取服务器列表数据
const getServerSideDataSource = () => {
  gridApi.value?.setGridOption('serverSideDatasource', {
    getRows: async (params: IServerSideGetRowsParams) => {
      try {
        loadingCellRendererParams.loadingMessage = 'Loading'
        if (isEmpty(params.request.groupKeys)) {
          // 一级分组框架
          gridOptions.maxConcurrentDatasourceRequests = 1
          gridApi.value?.hideOverlay()
          const groupRowData = await groupRowDataFormatter(params)
          const tableGroupDataTotal: number = groupLitsData.group?.list?.length ? groupLitsData.group?.list.length : 0
          if (Number(tableGroupDataTotal) === 0) {
            gridApi.value?.showNoRowsOverlay()
          }
          params.success({ rowData: groupRowData.firstLevelGroupData, rowCount: tableGroupDataTotal })
        } else if (
          props.type === 'account-balance' &&
          props.summaryParams.subGroup !== 'NONE' &&
          params.parentNode.level === 0
        ) {
          // 分组为二级分组
          const groupRowData = await groupRowDataFormatter(params)
          const tableGroupDataTotal: number = groupRowData.secondLevelGroupData[params.parentNode.data?.index]?.length
            ? groupRowData.secondLevelGroupData[params.parentNode.data?.index]?.length
            : 0
          params.success({
            rowData: groupRowData.secondLevelGroupData[params.parentNode.data?.index],
            rowCount: tableGroupDataTotal
          })
        } else {
          const tableGroupListData = await getTableGroupListData(params)
          params.success({
            rowData: tableGroupListData,
            rowCount: groupLitsData.totalCount
          })
        }
      } catch (error) {
        console.log(error)
        loadingCellRendererParams.loadingMessage = 'ERR'
        params?.fail()
      }
    }
  })
}

// ag-gridAPI初始化
const onGridReady = async (params: DetailGridInfo) => {
  // 获取gridApi
  gridApi.value = params?.api as GridApi
  ledgerStore.setAgGridApi(params?.api as GridApi)
  // 获取服务器列表数据
  getServerSideDataSource()
}

// 刷新列表
const resetList = () => {
  groupLitsData.group = {}
  groupLitsData.listData = {}
  groupLitsData.total = {}
  tableDataList.value = []
  groupLitsData.totalCount = 0
  const firstDisplayedRowIndex = gridApi.value?.getFirstDisplayedRowIndex()
  if (firstDisplayedRowIndex) {
    gridApi.value?.ensureIndexVisible(firstDisplayedRowIndex || 0, 'top')
  }
  gridApi.value?.refreshServerSide({ route: [], purge: true })
}

// 当前行唯一标识
const getRowId = (params: any) => {
  // eslint-disable-next-line no-nested-ternary
  const rowId = params.data?.label
    ? `${params.data?.label}${params.data?.index}`
    : // eslint-disable-next-line no-nested-ternary
      params?.parentKeys
      ? `${params?.parentKeys[0]}${params.data?.rowId}`
      : params.data?.rowId
  return uniqueId(rowId)
}

const getRowHeight = (params: any) => {
  if ((params.node.level === 0 || params.node.level === 1) && params.data?.type === 'total') {
    return 80
  }
  return 42
}
// 是否展开分组
const isServerSideGroupOpenByDefault = () => {
  if (props.type === 'account-balance') return true
  return false
}

defineExpose({ groupLitsData, resetList })

onBeforeUnmount(() => {
  gridApi.value?.destroy()
})
</script>

<style scoped lang="scss">
.elv-ledger-process-table {
  flex: 1;
  min-height: 0;
  position: relative;
  box-sizing: border-box;
  background: url('@/assets/img/screener-watermark.png') no-repeat 58%;
  background-size: 631px 200px;
  margin-left: -1px;

  .elv-ag-theme-alpine {
    --ag-column-border: 1px solid #e4e7eb;
    --ag-header-column-separator-color: #e4e7eb;
    --ag-header-column-separator-width: 1px;
    --ag-background-color: transparent;
    --ag-foreground-color: #0e1420;
    --ag-header-foreground-color: rgb(162, 174, 186);
    --ag-header-background-color: #f9fbfe;
    --ag-odd-row-background-color: transparent;
    // --ag-header-cell-hover-background-color: rgba(255, 255, 255 , 1)
    // --ag-odd-row-background-color: rgba(248, 250, 254, 0.5);
    --ag-font-size: 14px;
    // --ag-row-height: 42px;
    // --ag-line-height: 42px;
    --ag-list-item-height: 50px;
    --ag-header-column-resize-handle-height: 0%;
    --ag-header-column-resize-handle-width: 1px;
    --ag-grid-size: 5px;
    --ag-borders: 1px solid;
    --ag-border-color: #e4e7eb;
    --ag-row-border-color: #e4e7eb;
    // --ag-font-family: 'Barlow-Regular';
    --ag-borders-row: solid 1px;
    --ag-cell-horizontal-border: solid #e4e7eb;
    --ag-header-column-separator-display: block;
  }

  :deep(.ag-root-wrapper) {
    border-color: #cedee0;

    .ag-row-hover.ag-full-width-row.ag-row-group:before,
    .ag-row-hover:not(.ag-full-width-row):before {
      background-color: #f9fafb;
    }
  }

  :deep(.ag-horizontal-left-spacer, .ag-horizontal-right-spacer) {
    overflow-x: overlay;
  }

  :deep(.ag-body-horizontal-scroll-viewport) {
    overflow-x: overlay;
  }

  :deep(.ag-header-group-cell-with-group) :deep(.ag-header-cell-label) {
    color: rgb(44, 46, 48);
    font-size: x-small;
    justify-content: right;
  }

  :deep(.ag-header) {
    height: 36px !important;
    min-height: 36px !important;
    border-bottom-color: #cedee0;
  }

  :deep(.ag-sticky-top) {
    z-index: 99;
  }

  :deep(.ag-header-row):nth-child(1) {
    height: 36px !important;
    line-height: 34px;
    font-size: 11px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;

    color: $elv-color-9BA8B5;
    //   letter-spacing: 0.075em;
    background-color: #eef4fb;
  }

  :deep(.ag-pinned-left-header),
  :deep(.ag-pinned-left-cols-container) {
    box-shadow:
      6px 0px 12px rgba(0, 0, 0, 0.05),
      1px 0px 4px rgba(23, 83, 235, 0.1);
    z-index: 100;
  }

  .ag-floating-top .ag-row {
    background-color: #000;
  }

  :deep(.ag-floating-bottom-viewport) {
    background: #f9fafb;
    height: 52px;

    .ag-row-pinned {
      height: 100% !important;
    }

    .ag-cell {
      height: 52px !important;
    }
  }

  .ag-floating-bottom .ag-row {
    background-color: #000;
  }

  :deep(.ag-header-cell) {
    color: $elv-color-E4E7EB;
    padding-left: 0;
    padding-right: 0;

    &::after {
      width: var(--ag-header-column-separator-width);
      background-color: var(--ag-header-column-separator-color);
    }
  }

  :deep(.ag-overlay) {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 1%, rgba(255, 255, 255, 1));
  }

  :deep(.ag-floating-bottom-full-width-container) {
    height: 52px;
  }

  :deep(.ag-floating-bottom) {
    height: 52px !important;
    min-height: 52px !important;
    border-top-color: #cedee0;

    .ag-cell {
      font-family: 'Barlow' !important;
      font-weight: 700 !important;
      font-size: 12px !important;
    }
  }
  // 隐藏工具面板
  :deep(.ag-side-buttons) {
    display: none;
  }

  :deep(.ag-row) {
    .ag-cell {
      border-left-width: 0px;
      border-top-width: 0px;
      border-bottom-width: 0px;
      display: flex;
      align-items: center;
    }
  }

  :deep(.ag-header-group-cell-label) {
    justify-content: center;
  }

  :deep(.el-button.is-text:not(.is-disabled):active) {
    background-color: $elv-color-F9FBFE;
  }

  :deep(.ag-theme-alpine .ag-tabs-header) {
    display: none;
  }

  :deep(.ag-theme-alpine .ag-tabs-body) {
    width: 150px;
    box-sizing: border-box;
  }

  :deep(.ag-tabs) {
    min-width: auto;
  }

  :deep(.ag-row-group) {
    height: 42px;
    line-height: 42px;

    .ag-cell-value {
      height: 42px !important;
      line-height: 42px !important ;
    }

    .ag-cell {
      font-family: 'Barlow' !important;
      font-weight: 700 !important;
    }
  }

  :deep(.ag-row-level-0) {
    .ag-cell-value {
      &:has(.elv-table-group-rows) {
        padding-left: 11px;
      }

      &:has(.elv-financials-total) {
        height: 80px !important;
      }

      &.ag-cell-first-right-pinned {
        &:has(.elv-financials-cell-event-main.is-pinned):hover {
          background: #edf0f3;
        }
      }
    }

    &:hover {
      background: #f9fafb;

      .elv-ag-index-select {
        display: flex;
      }
    }
  }

  :deep(.ag-row-level-0.ag-row-group) {
    background: v-bind('groupBackgroundColor');
  }

  :deep(.ag-row-level-1) {
    background: v-bind('subGroupBackgroundColor');

    .elv-table-group-rows-content__label {
      background: $elv-color-DCE6FF;
      border: 1px solid $elv-color-C4D6FF;
    }

    .elv-financials-ledger-cell-group {
      font-weight: 600;
    }

    .ag-cell-value {
      &:has(.elv-financials-total) {
        height: 80px !important;
      }

      &:has(.elv-table-group-rows) {
        padding-left: 11px;
        padding-right: 0px;
      }
    }
  }

  :deep(.ag-row-level-2) {
    background: v-bind('subGroupBackgroundColor');

    .elv-table-group-rows-content__label {
      background: $elv-color-D7F7D2;
      border: 1px solid $elv-color-CCEAC7;
    }

    .ag-cell-value {
      &:has(.elv-ag-group-index) {
        border-right: 0px;
      }

      &:has(.elv-table-group-rows) {
        padding-left: 35px;
        padding-right: 0px;
      }
    }
  }

  :deep(.ag-row-level-3) {
    .elv-ag-index {
      display: none;
    }

    .ag-cell-value {
      &:has(.elv-ag-index) {
        border-right: 0px;
      }

      &:has(.elv-table-group-rows__index) {
        padding-left: 38px;
      }
    }
  }

  :deep(.ag-row-group-contracted) {
    &:first-of-type {
      .ag-cell-value {
        padding-top: 0px;
      }
    }

    .ag-cell {
      border-width: 0px;
      border-right-width: 1px;
    }

    .elv-ag-group-arrow {
      height: 42px;
    }
  }

  :deep(.ag-pinned-right-floating-bottom) {
    box-shadow:
      6px 0px 12px rgba(0, 0, 0, 0.05),
      1px 0px 4px rgba(23, 83, 235, 0.1);

    .ag-row {
      background-color: #f9fafb;
      height: 52px !important;
    }

    .ag-cell-value {
      height: 52px !important;

      &:nth-child(1) {
        border-right: 0px;
      }
    }
  }

  :deep(.ag-pinned-right-header) {
    box-shadow:
      6px 0px 12px rgba(0, 0, 0, 0.05),
      1px 0px 4px rgba(23, 83, 235, 0.1);
  }

  :deep(.ag-pinned-right-cols-container) {
    position: relative;

    &::before {
      position: absolute;
      width: 4px;
      height: 100%;
      content: '';
      left: -4px;
      top: 0px;
      background: linear-gradient(270deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%);
    }
  }

  :deep(.ag-row-group-expanded) {
    .ag-cell {
      border-left-width: 0px;
      border-top-width: 0px;
    }
  }

  :deep(.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within) {
    border: none;
    border-right: 1px solid $elv-color-E4E7EB !important;
  }

  :deep(.ag-header-cell-comp-wrapper) {
    height: 34px;
  }

  :deep(
      .ag-pinned-left-header
        .ag-header-row-column-group
        .ag-header-group-cell-with-group:nth-child(2)
        .ag-header-group-cell-label
    ) {
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 11px;
    transform: scale(0.91);
    line-height: 14px;
    color: $elv-color-9BA8B5;
    justify-content: flex-start !important;
    text-transform: lowercase;
  }
}
</style>
