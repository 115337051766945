import { isEmpty } from 'lodash-es'
import { processTableFieldList } from '@/config/reports'
import subHeaderCell from '@/components/Reports/Cell/SubHeaderCell.vue'
import indexCell from '@/pages/Financials/Project/Ledger/components/Cell/IndexCell.vue'

// 加载字段配置
const configCellModules: Record<string, any> = import.meta.glob('./*.ts', {
  eager: true
})
const configCellModulesMap = new Map()
Object.keys(configCellModules).forEach((modules) => {
  const key = configCellModules[modules]
  if (!isEmpty(key.default)) {
    configCellModulesMap.set(key.default?.type, key.default?.config)
  }
})
// 表格展示内容
const tableValueGetter = (field: any, type: string) => {
  return (params: any) => {
    return { cellName: field.name, tableType: type, ...params.data }
  }
}

const tableWidthGetter = (item: any) => {
  let width: any = 160
  if (item.name === 'Date' || item.name === 'Chart of account') {
    width = 200
  }
  if (item.name === 'Journal') return 300
  return width
}

export const useLedgerTableConfigStore = defineStore({
  id: 'ledgerTableConfigStore',
  state: () => {
    const ledgerTableConfigState: {
      processTableConfig: any[]
    } = {
      processTableConfig: []
    }
    return ledgerTableConfigState
  },
  actions: {
    /**
     * 获取过程表配置
     * @param {string} type 表格类型(generalLedger、transactions、holdings、accounts、contacts)
     * @returns
     */
    getProcessTableConfig(type: string, pinnedList: string[] = [], pinned: string = 'left', subGroup: boolean = false) {
      const { fieldGroupList } = processTableFieldList[type]
      const tableConfig: any[] = [
        // 定义索引和分组字段配置
        {
          headerName: '',
          colId: '#',
          field: '#',
          suppressHeaderMenuButton: false,
          headerComponent: subHeaderCell,
          cellRenderer: indexCell,
          suppressAutoSize: false,
          suppressSizeToFit: true,
          valueGetter: tableValueGetter({}, type),
          width: 36,
          cellStyle: {
            padding: 0
          },
          colSpan: (params: any) => {
            const rowData = params.node
            if (rowData.rowPinned === 'bottom' && rowData.rowIndex === 0) {
              return 2
            }
            if (rowData.data?.type === 'total') {
              return 8
            }
            return 1
          },
          resizable: false // 是否开启调整列大小，就是拖动改变列大小
        }
      ]

      if (isEmpty(fieldGroupList)) {
        return tableConfig
      }

      // 处理字段分组和字段展示
      fieldGroupList?.forEach((fieldItem: any, i: number) => {
        // 根据字段类型找到具体的字段配置
        const config = configCellModulesMap.get(fieldItem.type) ?? {}
        // 拼接分组数据
        const formatField: any = {
          headerName: fieldItem.name, // 标题名
          headerComponent: subHeaderCell,
          headerTooltip: '',
          field: fieldItem.fieldId, // 数据
          colId: fieldItem.fieldId,
          rowGroup: false,
          pinned: pinnedList.includes(fieldItem.name) ? pinned : null,
          width: tableWidthGetter(fieldItem),
          columnTypes: fieldItem.type,
          valueGetter: tableValueGetter(fieldItem, type),
          columnIndex: i,
          colSpan: (params: any) => {
            const rowData = params.node
            if (rowData?.level === 0 && params?.colDef?.headerName === 'Date') {
              return 4
            }
            return 1
          },
          hide: false,
          ...config
        }
        tableConfig.push(formatField)
      })

      // 分组字段的配置
      const groupFieldConfig = {
        headerName: 'group',
        field: 'firstLevelGroup',
        rowGroup: true,
        showRowGroup: true,
        suppressAutoSize: false,
        suppressSizeToFit: true,
        hide: true
      }
      // 二级分组
      const secondGroupFieldConfig = {
        headerName: 'secondLevelGroup',
        field: 'secondLevelGroup',
        rowGroup: true,
        showRowGroup: true,
        suppressAutoSize: false,
        suppressSizeToFit: true,
        hide: true
      }

      tableConfig.push(groupFieldConfig)
      if (subGroup) {
        tableConfig.push(secondGroupFieldConfig)
      }
      this.processTableConfig = tableConfig
      return tableConfig
    }
  }
})
