<template>
  <template
    v-if="
      (!isGroup && props.params?.value?.cellName === 'Auxiliary code') ||
      (isGroup && props.params?.value?.tableType === 'accountBalance')
    "
  >
    <div class="elv-ledger-cell-auxiliaryCode-main">
      <template v-if="props.params?.data?.auxiliaryValueList?.length">
        <div
          v-for="item in props.params?.data?.auxiliaryValueList"
          :key="item.auxiliaryTypeId"
          class="elv-ledger-cell-auxiliaryCode-item"
        >
          {{ item?.auxiliaryType?.name }}:
          {{ getAuxiliaryValue(item) }}
        </div>
      </template>
      <div v-else-if="props.params?.data?.auxiliaryEntityAccount?.name" class="elv-ledger-cell-auxiliaryCode-item">
        <!-- {{ item?.auxiliaryType?.name }}: -->
        {{ props.params?.data?.auxiliaryEntityAccount?.name }}
      </div>
    </div>
  </template>
</template>
<script lang="ts" setup>
const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const isGroup = computed(() => {
  return props.params.node?.group
})

const getAuxiliaryValue = (item: any) => {
  if (item?.auxiliaryType?.type === 'ENTITY_ACCOUNT') {
    return item?.entityAccount?.name
  }

  if (item?.auxiliaryType?.type === 'COUNTERPARTY') {
    return item?.counterparty?.name
  }

  if (item?.auxiliaryType?.type === 'ITEM') {
    return item.auxiliaryItem?.value
  }

  return null
}
</script>
<style lang="scss">
.elv-ledger-cell-auxiliaryCode-main {
  display: flex;
  align-items: center;

  .elv-ledger-cell-auxiliaryCode-item {
    display: flex;
    padding: 4px 12px;
    height: 23px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    color: #0e0f11;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 4px;
    background-color: #f9fafb;
    border-radius: 12px;
    border: 1px solid #edf0f3;
    white-space: nowrap;

    &:last-of-type {
      margin-right: 0;
    }
  }
}
</style>
